import React, { Component } from "react";
import close_btn from '../../../images/close_btn.svg';
import Payment from '../../../images/payment_new.png';
import { userService } from "../../../_services";
//import PaymentForm from "../../../components/PaymentForm";Commented because not in use
import signupcheck from '../../../images/payment_success.svg';
import { GoogleSpreadsheet } from "google-spreadsheet";
import PaymentFormNew from "../../PaymentFormNew";
class OrderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserId: '',
            UserName: '',
            Fname: '',
            Lname: '',
            Phone: '',
            Email: '',
            Address1: '',
            Address2: '',
            Country: 'United States',
            State: '',
            City: '',
            Zip: '',
            fullName: '',
            carddetails: '',
            title: 'Payment Information',
            loaded: false,
            Isshowpayment: false,
            show_Userdetail_popup: true,
            SuccessMessage: false,
            orderid: '',
            gn: '',
            date:'',
            errors: {
                UserName: '',
                Fname: '',
                Lname: '',
                Phone: '',
                Email: '',
                Address1: '',
                Address2: '',
                State: '',
                City: '',
                Zip: '',
                orderid: '',
                gn: '',
                date:''
            },
            AddressData: {
                Country: [
                    'United States'
                ],
                State: [
                    'Please select state',
                    'Alabama',
                    'Alaska',
                    'Arizona',
                    'Arkansas',
                    'California',
                    'Colorado',
                    'Connecticut',
                    'Delaware',
                    'District of Columbia',
                    'Florida',
                    'Georgia',
                    'Hawaii',
                    'Idaho',
                    'Illinois',
                    'Indiana',
                    'Iowa',
                    'Kansas',
                    'Kentucky',
                    'Louisiana',
                    'Maine',
                    'Maryland',
                    'Massachusetts',
                    'Michigan',
                    'Minnesota',
                    'Mississippi',
                    'Missouri',
                    'Montana',
                    'Nebraska',
                    'Nevada',
                    'New Hampshire',
                    'New Jersey',
                    'New Mexico',
                    'New York',
                    'North Carolina',
                    'North Dakota ',
                    'Ohio',
                    'Oklahoma',
                    'Oregon',
                    'Pennsylvania',
                    'Rhode Island',
                    'South Carolina',
                    'South Dakota',
                    'Tennessee',
                    'Texas',
                    'Utah',
                    'Vermont',
                    'Virginia',
                    'Washington',
                    'West Virginia',
                    'Wisconsin',
                    'Wyoming',
                    'American Samoa',
                    'Guam',
                    'Northern Mariana Islands',
                    'Puerto Rico',
                    'Virgin Islands',
                ]
            },
            stuck: false

        };
        this.noerrors = {
            UserName: '',
            Fname: '',
            Lname: '',
            Phone: '',
            Email: '',
            Address1: '',
            Address2: '',
            State: '',
            City: '',
            Zip: '',
            orderid: '',
            gn: '',
            date: ''
        }
        this.submit_user_detail = this.submit_user_detail.bind(this);
        this.toggle_user_detail = this.toggle_user_detail.bind(this);
        this.toggle_Payment_pop = this.toggle_Payment_pop.bind(this)
    }
    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.GetUserAccountInfo(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
        this.setState({ show_Payment_popup: true });
    }
    componentWillMount() {
        userService.GetPaymentStuff('')
            .then(
                apidata => {
                    if (apidata.length > 0) {
                        try {
                            const that = this;
                            let sqPaymentScript = document.createElement('script');
                            sqPaymentScript.src = apidata[0].sqPaymentScript;
                            sqPaymentScript.type = "text/javascript"
                            sqPaymentScript.async = false;
                            sqPaymentScript.onload = () => {
                                that.setState({
                                    loaded: true
                                })
                            };
                            document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
                        }
                        catch (error) { }
                    }
                });
    }
    toggle_SucccessMessage = (e) => {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        let apptype = Usermeta_Data.split(':')[0];
        userService.GoogleSheetEntry(apptype)
            .then(
                apidata => {
                    if (apidata.length > 0) {
                        try {
                            this.setState({ orderid: apidata[0].orderId, gn: apidata[0].groupNumber, date: apidata[0].date });
                            this.GetGooglesheetEntry(this.state.orderid, this.state.gn, this.state.date)
                            }
                        catch (error) { }
                    }
                });
        this.setState({ SuccessMessage: e });
    }
        GetGooglesheetEntry(orderid1,gn1,date1){
            //const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
            //const SHEET_ID = process.env.REACT_APP_SHEET_ID;
            //const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL;
            //const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY;Commented because not in use

            let getlogin = window.location.href;

            if (getlogin.toLowerCase().includes("release-backoffice") || getlogin.toLowerCase().includes("dev-backoffice") || getlogin.toLowerCase().includes("localhost:")) {
                const doc = new GoogleSpreadsheet("19p0NYQYc6SjyA_3hmqdHbjA5mhpqCmZkA_v6OX65RfY");
                const appendSpreadsheet = async (row) => {
                    try {
                        await doc.useServiceAccountAuth({
                            client_email: "addorderdetail@smart-theory-332406.iam.gserviceaccount.com",
                            private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDZUM2CWZDDvlHd\neMhHWpVpScm6DS+T4ntZ9YrMZn2cJqY4cjhR2Krhs8vgelOpO60N+VdUZRcYfF9k\nP3ZfSA3XW4EA4EbHtBOt+247fwFop/avuzVDlhxhyHCvAT9xx5an0eJF3WPsz4Ce\ntiNLXrOZV8pgiQPwR3jzd5M2lUZBt0mBRcTp/7P7iNcCF3riaTo/0Wu6bnDRr3N/\nW37WVLYjTopZFP+3XtXJtIVulIbRIXBkxslMV0iwfu6Y3vW43HQ5jkDPN/HlMNYD\n+Xsh9dybk5fwObI83VGxIBnLyAOl84tTQmStBYnZuKN3QmeZDXNXZKLSNA7D8mPl\nqoGcAaFbAgMBAAECggEAAbFZ8HZ/JK8yVw3OTZP8jvXmrt7UbIv6lwBNJPt1ZgZ9\nC/qDystrxVdPsr9xMGPJHM9A6WTiMawiWBcQdnyKsw9A6X9Vvc9+yb+oI6mBGs4Y\nI1VLfGvKa86SZiKV2m6KE2OUpiOr2BfjshjMHJWvw3WGG4tlrvT3kbnwOSAfjHp7\n2FhWyDqf/bMg5bZuXuPjdVEEilC2H+Vno1QB73DhYrvKYES8vytRZx6cZwtUbxkz\nQWCHDZrGjc59I3SA/ZzdrNYqAOmwzx0uziDaBbHtLMg2omXFeZ7cKX32yTH7HCBS\nK8mONxqW1C25Mszvh4KJAZWQol7cjhFPOzpy1VKnlQKBgQD0EnaJ++AU1sNgz2S7\n9vkggz3P/BX38kwD0fIyT3l1xX6MJvRE5kRPXXi6g1rNPslnYQ3finBArLOb9p3n\n4/LhTSKqsdf9amChNXAtxTZB8pJZsvJ6lD6SX5Q7f8GJp7Djcr/clLqP1ZuYiogE\nvNu4nIbWESP6Pz0uhQUUNNb3BwKBgQDj75hBakihvSh0BWa7P44DkbaIRR1UNiC5\nRsmzGmF7O1+b4NVAQSZGA+vMGD2/X+ECdOmXMxHT0iCZnqwlF23RQT/Jujy9qrUq\n30HEcBSrGqTDkxOhRamjT3otcwGPQH0ttwZqrdQphXdeaEXhQfXLuFLIhiRtUF9O\nrskAMoS6DQKBgQCv111vq/eVC+VXKhP5F8YElolB/uwU5ou9dNR5SqjHILI7ZxU1\nTnCIfV8tgNU6abHQuSu1kZeuAL6l7td06dViXKBNOjPSb4GOo2FemGauvIST+0iW\n55bs/c9dzn0xH49hTprL4JtIZSzkiWnVf/sM1jiOoWq6MDVz/oHaPPdioQKBgDf9\n7FXtaOgqUlyl0wWeaCFiUweIitrb7Q/2XqfbjCS8ghEFmv0Mw/0hC3R4Y/q1780t\nsRAOYIu7K3zxKHJ4Hebwc133nOIupBBjBYHe0oTxp5XPdFwCg9h1NpshjDmHavim\n5DGif+KvQZJ0ZO4fyOSBY/yUTzB3NOJcN04QGuClAoGAMhLpwabZ4kV4dPFUS2vN\nAN+g0qu+RgHegerM8HVSxi6Mio1sECcRFxEIB/MFm5VsYFGS3T2yYS0Jv0IMeeIO\nv1rmCART/NnSjTSnTTQA+e0QEXMM6fuNon08jpZJSYtHVQD1XnqGgaKkwKcyPPKR\nWebLRqugsnOZ1ri27eaUSAA=\n-----END PRIVATE KEY-----\n"
                        });
                        // loads document properties and worksheets
                        await doc.loadInfo();
                        //const sheet = doc.sheetsById["0"];Commented because not in use
                       // const result = await sheet.addRow(row);Commented because not in use
                    } catch (e) {
                        console.error('Error: ', e);
                    }
                };
                const newRow = { OrderID: orderid1, Date: this.state.date, FirstName: this.state.Fname, LastName: this.state.Lname, Group_Number: gn1, Email: this.state.Email, Address1: this.state.Address1, Address2: this.state.Address2, City: this.state.City, State: this.state.State, Zip: this.state.Zip };
                appendSpreadsheet(newRow);
            }
            else {
                const doc = new GoogleSpreadsheet("1WdcHyaZGax6Bpo6sY7r6vbRaiOuZBsC-aJkAWntYIHo");
                const appendSpreadsheet = async (row) => {
                    try {
                        await doc.useServiceAccountAuth({
                            client_email: "paymententry@crafty-key-333107.iam.gserviceaccount.com",
                            private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCZXP7jo93HHaaU\n3vqbnHxAnbjRcBoTnNJhsnJRIkEQGjOZAwfQJdTjIjsEHuPf0W0m02DRSj47S2l7\ngTWtkirkTdCQO48z8t4r8wG4yG5/GplAZz1vLc4Mfmv4WRSYdDoCVAtFeqSQGWwH\nsvjplxiGppGnLILl08FsEG3PBrU8uv0E6Sgrv8Al453XaJrI2T38uvxG15yIyZYl\nyGYMpD4HLxpWcZqRH3GIqDg5gBndkd34zcwDW6TYQEvf+XWnSeE8Rvf1JlXOFho+\nmOdU1IE+ETvmEwll6F01okKoRuvC67IfkmuGiieENXRve7NLncqPYUwX2TiE7H2X\n9CctJVYFAgMBAAECggEAG8xOayadoWTPd/Z+yEvx/ahZAqVmJJ3tSEYXEHu7/VYy\neg6hkOn6j3z544JR3ZTRSxNPXkAtzYOayAcgmqh+28j6/9m72Deya5btfffeh21j\nz9emvAs6aOtVWLuUzxlqFlfzfxF+2JuzCCY5leWMTYPNrNaVl2Dtk0/bB4Z4vVwD\nX4X9NFShUEIQzghlrFGdht1nztluOX+1ZGnhfhf5FfbZtSlOR3UlUuSjvaEWMAhg\nghHru/s8PMdsDusTMRv3tgjbLac70nTYsmOdw7eIaEM16WeU0McHSNsaA7h8Nm7u\nsyS38HpgvDlRF6uWDq8qD1x8HSGhORVTLEQWEDEYNQKBgQDSpwjysfYjW77Kx17v\nB0ZYViroV1RHEPXLxhbPT4z+x7eor8KW7JtHSnKUY5bnuswbYq6Sq/vjizH8V8Hq\nFvdI+YyXzU+mBB0Hjat/4PDp8IDFQ/Gte7fVHIFYlqmHO9pUfZqPjqqnTVBOj0a3\nYLGcoFD+NTcCZkrbokFrvesmFwKBgQC6YMcAIYqQHslcudo5BtTS5CfuQnTOoJS8\nV3oemCofHgV4Ps8uCa/16s8yNwAQpMD21kpfYVpGyQtGicQICUhtPtu1uOMW7924\n0eShM4LIa9nnexHnCbi1cXTQUdMvPBGJCLZuAz60+p3qkZjgiuNe6Dhr2VRMWDPv\nNL/vhDFSQwKBgQCpr9QNbD2SDf2K6z8V3Q5ValP2BSLmfEsDcvY82SACvG69e5eM\na9xJeTcDQZX15Qs1pDBsBg5KJX6Gf0q9fvKD3kHappBac0XPJ9fWkyR9zjZssWal\nENvs16F58yNsxFIDbYpryci+LBFctx2H6mQofxu/Kq69SBlVA0cfu0UI0QKBgHjD\nto5GLYN+/1i+8ECdRY+02I7QruEoOdfkErP6QujQyhiFEjexfTuqP0E5VCh0qLgI\nWD5/VUCiilTVdZkDctOetDyGpVa6a/+SuUMennKBN7t/CjWbHf0rm9ZT/rQPhwk1\nBQg5wAsCjQpt9xIfzH41mEGBm9awHE617lwjWtYDAoGBAKSQ8+sa/YelAXcqc2J/\nUQ5Bqf7z0mxUCMIrtAc1Vt7V+xXlCHAG050adXZJ56tSqHVOINn4xn7ZU0yjxkEg\nRUYLahOK4jjBIrSZLZe0Osyu/HoD6o3VxlE/PJVX9Ag2xSgJL28JLBSN/5JOh+0v\nF1Hjm/gg+H17pFSNHRNQVv+6\n-----END PRIVATE KEY-----\n"
                        });
                        // loads document properties and worksheets
                        await doc.loadInfo();

                        //const sheet = doc.sheetsById["0"];Commented because not in use
                        //const result = await sheet.addRow(row);Commented because not in use
                    } catch (e) {
                        console.error('Error: ', e);
                    }
                };
                const newRow = { OrderID: orderid1, Date: this.state.date, FirstName: this.state.Fname, LastName: this.state.Lname, Group_Number: gn1, Email: this.state.Email, Address1: this.state.Address1, Address2: this.state.Address2, City: this.state.City, State: this.state.State, Zip: this.state.Zip };
                appendSpreadsheet(newRow);
            }
            
    }
    toggle_user_detail = (e) => {
        e.stopPropagation();
        this.setState({ show_Userdetail_popup: false, SuccessMessage: false, Isshowpayment: false, show_Payment_popup: false });
    }
    toggle_Payment_pop = (e) => {
        e.stopPropagation();
        this.setState({ Isshowpayment: !this.state.Isshowpayment, show_Payment_popup: true, show_Userdetail_popup: true });
    }
    GetUserAccountInfo(UserId, UserName) {
        try {
            userService.GetUserAccountInfo(UserId, UserName)
                .then(
                    apidata => {
                        try {
                            if (apidata.length > 0) {
                                if (apidata[0].code !== "-1") {
                                    this.setState({ UserId: UserId, UserName:apidata[0].username,Fname: apidata[0].fname, Lname: apidata[0].lname, Phone: apidata[0].phone, Email: apidata[0].email, Address1: apidata[0].address1, Address2: apidata[0].address2, State: apidata[0].stateName, City: apidata[0].cityName, Zip: apidata[0].zipCode });
                                }
                                else {

                                }
                            }
                            else {

                            }
                        }
                        catch (error) {
                        }
                    }
                );
        }
        catch (error) {
        }
    }
    submit_user_detail = (e) => {
        e.stopPropagation();
        try {
            let errors = this.state.errors;
            if (this.state.Fname.length < 3)
                errors.Fname = 'Minimum 3 characters long';
            if (this.state.Fname.length > 20)
                errors.Fname = 'Maximum 20 characters long';
            //errors.Fname = 'First Name must be at least 3 characters long';
            if (this.state.Lname.length < 2)
                errors.Lname = 'Minimum 2 characters long';
            if (this.state.Lname.length > 20)
                errors.Lname = 'Maximum 20 characters long';
            //errors.Lname = 'Last Name must be at least 2 characters long';
            if (this.state.Address1.length < 1)
                errors.Address1 = 'Please Input street address';
            if (this.state.Address1.length > 25)
                errors.Address1 = 'Please Input valid street address';
            if (this.state.Address2.length > 25)
                errors.Address2 = 'Please Input valid street address';
            if (this.state.State === 'Please select state' || this.state.State === '')
                errors.State = 'Please Select State';
            if (this.state.City.length < 2)
                errors.City = 'Please input city';
            if (this.state.City.length > 35)
                errors.City = 'Please input valid city';
            if (this.state.Zip.length < 5 || this.state.Zip.length > 5)
                errors.Zip = 'Please input valid zipcode';
            if (JSON.stringify(errors) !== JSON.stringify(this.noerrors)) {
                this.setState({ errors: errors });
            }
            else {
                let states_data = this.state;
                this.SetUserAccountInfo(states_data.UserId, states_data.UserName, states_data.Fname, states_data.Lname, states_data.Email, states_data.Phone, states_data.Address1, states_data.Address2, states_data.Country, states_data.State, states_data.City, states_data.Zip);
                
            }
        }
        catch (error) {
        }
    }
    SetUserAccountInfo(UserId, UserName, Fname, Lname, Email, Phone, Address1, Address2, CountryName, StateName, CityName, ZipCode) {
        try {
            userService.SetUserAccountInfo(UserId, UserName, Fname, Lname, Email, Phone, Address1, Address2, CountryName, StateName, CityName, ZipCode)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code !== '-1') {
                                this.setState({ SuccessMessage: false, show_Userdetail_popup: false, Isshowpayment: true });
                            }
                        }}
                );
        }
        catch (error) {
        }
    }
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name] = '';
        this.setState({ errors: errors });
    }
    HandleInput = (event) => {
        let errors = this.state.errors;
        if (event.target.name === "Fname" || event.target.name === "Lname") {
            if (event.target.value !== "") {
                let special = /^[a-zA-Z]+$/g;
                if (event.target.value.match(special)) {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name] = '';
                    this.setState({ errors: errors });
                }
                else {
                    return false;
                }
            }
        }
        if (event.target.name === "City") {
            if (event.target.value !== "") {
                let special = /^[a-zA-Z ]*$/;///^[a-zA-Z]+$/g;
                if (event.target.value.match(special) || event.target.value === '') {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name] = '';
                    this.setState({ errors: errors });
                }
                else {
                    return false;
                }
            }
        }
        if (event.target.name === "Zip") {
            let str = event.target.value;
            str = str.replace(/[^0-9]/g, '');
            this.setState({ Zip: str });
            if (event.target.value !== "") {
                let special = /^[0-9\b]+$/;
                if (event.target.value.match(special) || event.target.value === "") {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name] = '';
                    this.setState({ errors: errors });
                }
                else {
                    return false;
                }
            }
        }
        else {
            this.setState({ [event.target.name]: event.target.value });
            errors[event.target.name] = '';
            this.setState({ errors: errors });
        }
    }
    handleChange = (event => {
        this.setState({ [event.target.name]: event.target.value });
    })
    dofocus = (event) => {
        event.target.previousElementSibling.focus();
    }
    render() {
        const { errors } = this.state;
        return (
            <div>
                {this.state.show_Payment_popup === true ?
                    <div className="contentarea signup-popup order-section">
                        <div className="content_sec">
                            <div className="add_url_pop payment_popup">
                                <div className="Mask">
                                    <div className="header">
                                        <h3>RxHacker Introductory Kit</h3>
                                        <div className="close" onClick={this.props.toggle_user_detail.bind(this)}>
                                            <img src={close_btn} alt=""/>
                                        </div>
                                    </div>
                                    <div className="body">
                                        <div className="scroll-section">
                                            <div className="Rectangle">
                                                <div className="Get-a-jump-start-wit"><span>Get a jump start with</span> Rxhacker Introductory Kit</div>
                                                <img src={Payment} className="Group-16" alt=""/>
                                                <div className="-Featuring-500-of-o">
                                                    <ul>
                                                        <li>Featuring 1000 of our standard cards</li>
                                                        <li>6 clear PVC displays</li>
                                                        <li>6 Box displays</li>
                                                        <li>Conveniently bundled up & assigned for you!</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {this.state.SuccessMessage ?
                                                <div className="right-view success-msg">
                                                    <img src={signupcheck} alt=""/>
                                                    <h3>Thank you for your payment.</h3>
                                                   You will receive an email with your<br />
                                                   receipt and shipping details

                                            </div> :

                                                <div className="right-view">
                                                    {
                                                        this.state.show_Userdetail_popup === true ?
                                                            <div>
                                                                <div className="personal_box UserInfo">
                                                                    <div className="Shipto">
                                                                        Ship To
                                                </div>
                                                                    <div className={errors.Fname.length > 0 ? 'errors same first-name' : 'same first-name'}>
                                                                        <input type="text" placeholder=" " name="Fname" value={this.state.Fname} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                                                        <span onClick={this.dofocus.bind(this)}>First Name</span>
                                                                        {errors.Fname.length > 0 &&
                                                                            <span className="error" onClick={this.removeError.bind(this)}>{errors.Fname} </span>}
                                                                    </div>
                                                                    <div className={errors.Lname.length > 0 ? 'errors same last-name' : 'same last-name'}>
                                                                        <input type="text" placeholder=" " name="Lname" value={this.state.Lname} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                                                        <span onClick={this.dofocus.bind(this)}>Last Name</span>
                                                                        {errors.Lname.length > 0 &&
                                                                            <span className="error" onClick={this.removeError.bind(this)}>{errors.Lname}</span>}
                                                                    </div>
                                                                    <div className={errors.Address1.length > 0 ? 'errors same full' : 'same full'}>
                                                                        <input type="text" placeholder=" " name="Address1" value={this.state.Address1} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                                                        <span onClick={this.dofocus.bind(this)}>Address Line 1</span>
                                                                        {errors.Address1.length > 0 &&
                                                                            <span className="error" onClick={this.removeError.bind(this)}>{errors.Address1}</span>}
                                                                    </div>
                                                                    <div className={errors.Address2.length > 0 ? 'errors same full' : 'same full'}>
                                                                        <input type="text" placeholder=" " name="Address2" value={this.state.Address2} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                                                        <span onClick={this.dofocus.bind(this)}>Address Line 2</span>
                                                                        {errors.Address2.length > 0 &&
                                                                            <span className="error" onClick={this.removeError.bind(this)}>{errors.Address2} </span>}
                                                                    </div>
                                                                    <div className={errors.State.length > 0 ? 'drpdown errors full same' : 'drpdown full same'}>
                                                                        <select defaultValue={this.state.State} value={this.state.State}
                                                                            onChange={this.handleChange} name="State">
                                                                            {this.state.AddressData.State.map(item => <option key={item} value={item} >{item}</option>)}
                                                                        </select>
                                                                        <span>State*</span>
                                                                        {errors.State.length > 0 &&
                                                                            <span onClick={this.removeError.bind(this)}>{errors.State} </span>}
                                                                    </div>
                                                                    <div className={errors.City.length > 0 ? 'errors same first-name' : 'same first-name'}>
                                                                        <input type="text" placeholder=" " name="City" value={this.state.City} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                                                        <span onClick={this.dofocus.bind(this)}>City*</span>
                                                                        {errors.City.length > 0 &&
                                                                            <span className="error" onClick={this.removeError.bind(this)}>{errors.City} </span>}
                                                                    </div>
                                                                    <div className={errors.Zip.length > 0 ? 'errors same last-name' : 'same last-name'}>
                                                                        <input type="text" placeholder=" " name="Zip" value={this.state.Zip} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                                                        <span onClick={this.dofocus.bind(this)}>Zip/Postal Code*</span>
                                                                        {errors.Zip.length > 0 &&
                                                                            <span className="error" onClick={this.removeError.bind(this)}>{errors.Zip} </span>}
                                                                    </div>
                                                                </div>
                                                                <div className="footer">
                                                                    <div className="yes_btn" onClick={this.submit_user_detail.bind(this)}>Save & Continue</div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    <div>
                                                        {this.state.Isshowpayment === true ?
                                                            <div>
                                                                <div className="shipping">
                                                                    <h3>Shipping Address</h3>
                                                                    <address>
                                                                        {this.state.Fname}  {this.state.Lname}
                                                                        <br></br> {this.state.Address1} {this.state.Address2} {this.state.State} {this.state.City}
                                                                        <br></br>USA, {this.state.Zip}
                                                                    </address>
                                                                    <div className="close" onClick={this.toggle_Payment_pop.bind(this)}>change</div>
                                                                </div>
                                                                <h2> Payment Information</h2>
                                                                {/*<PaymentForm paymentForm={window.SqPaymentForm} Fname={this.state.Fname} Lname={this.state.Lname} Address1={this.state.Address1} Address2={this.state.Address2} State={this.state.State} City={this.state.City} Zip={this.state.Zip} Email={this.state.Email} toggle_Payment_pop={this.toggle_Payment_pop.bind(this)} SuccessMessage={this.toggle_SucccessMessage} />*/}
                                                                <PaymentFormNew paymentForm={window.Square.payments} Fname={this.state.Fname} Lname={this.state.Lname} Address1={this.state.Address1} Address2={this.state.Address2} State={this.state.State} City={this.state.City} Zip={this.state.Zip} Email={this.state.Email} toggle_Payment_pop={this.toggle_Payment_pop.bind(this)} SuccessMessage={this.toggle_SucccessMessage} />

                                                            </div> : null
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}

            </div>
        )
    }
}
export default OrderPage;
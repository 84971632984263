import React, { Component } from "react";
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
import $ from 'jquery';
class Edit_user_pop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: '',
            Fname: '',
            Phone: '',
            Email: '',
            Address1: '',
            Country: 'United States',
            State: '',
            City: '',
            Zip: '',
            group_no: '',
            submit_msg: '',
            Commission: '',
            SecLevelCommission: '',
            AllowForGC: '',
            OverrideCommission: '',
            CreatedOn: '',
            save_loader: false,
            errors: {
                UserName: '',
                Fname: '',
                Phone: '',
                Address1: '',
                Country: '',
                State: '',
                City: '',
                Zip: '',
                Commission: '',
                SecLevelCommission: '',
                OverrideCommission: '',
            },
            AddressData: {
                Country: [
                    'United States'
                ],
                State: [
                    { name: 'Please select state', value: '' },
                    { name: 'Alabama', value: 'AL' },
                    { name: 'Alaska', value: 'AK' },
                    { name: 'Arizona', value: 'AZ' },
                    { name: 'Arkansas', value: 'AR' },
                    { name: 'California', value: 'CA' },
                    { name: 'Colorado', value: 'CO' },
                    { name: 'Connecticut', value: 'CT' },
                    { name: 'Delaware', value: 'DE' },
                    { name: 'District of Columbia', value: 'DC' },
                    { name: 'Florida', value: 'FL' },
                    { name: 'Georgia', value: 'GA' },
                    { name: 'Hawaii', value: 'HI' },
                    { name: 'Idaho', value: 'ID' },
                    { name: 'Illinois', value: 'IL' },
                    { name: 'Indiana', value: 'IN' },
                    { name: 'Iowa', value: 'IA' },
                    { name: 'Kansas', value: 'KS' },
                    { name: 'Kentucky', value: 'KY' },
                    { name: 'Louisiana', value: 'LA' },
                    { name: 'Maine', value: 'ME' },
                    { name: 'Maryland', value: 'MD' },
                    { name: 'Massachusetts', value: 'MA' },
                    { name: 'Michigan', value: 'MI' },
                    { name: 'Minnesota', value: 'MN' },
                    { name: 'Mississippi', value: 'MS' },
                    { name: 'Missouri', value: 'MO' },
                    { name: 'Montana', value: 'MT' },
                    { name: 'Nebraska', value: 'NE' },
                    { name: 'Nevada', value: 'NV' },
                    { name: 'New Hampshire', value: 'NH' },
                    { name: 'New Jersey', value: 'NJ' },
                    { name: 'New Mexico', value: 'NM' },
                    { name: 'New York', value: 'NY' },
                    { name: 'North Carolina', value: 'NC' },
                    { name: 'North Dakota', value: 'ND' },
                    { name: 'Ohio', value: 'OH' },
                    { name: 'Oklahoma', value: 'OK' },
                    { name: 'Oregon', value: 'OR' },
                    { name: 'Pennsylvania', value: 'PA' },
                    { name: 'Rhode Island', value: 'RI' },
                    { name: 'South Carolina', value: 'SC' },
                    { name: 'South Dakota', value: 'SD' },
                    { name: 'Tennessee', value: 'TN' },
                    { name: 'Texas', value: 'TX' },
                    { name: 'Utah', value: 'UT' },
                    { name: 'Vermont', value: 'VT' },
                    { name: 'Virginia', value: 'VA' },
                    { name: 'Washington', value: 'WA' },
                    { name: 'West Virginia', value: 'WV' },
                    { name: 'Wisconsin', value: 'WI' },
                    { name: 'Wyoming', value: 'WY' },
                    { name: 'American Samoa', value: 'AS' },//
                    { name: 'Guam', value: 'GU' },
                    { name: 'Northern Mariana Islands', value: 'MP' },
                    { name: 'Puerto Rico', value: 'PR' },
                    { name: 'Virgin Islands', value: 'VI' },
                ]
            },
        }
        this.noerrors = {
            UserName: '',
            Fname: '',
            Phone: '',
            Address1: '',
            Country: '',
            State: '',
            City: '',
            Zip: '',
            Commission: '',
            SecLevelCommission: '',
            OverrideCommission: ''
        }
        this.submit_setting = this.submit_setting.bind(this)
    }
    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.DetailOfUser(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], this.props.repid)
        $('body').css('overflow-y', 'hidden');
    }
    submit_setting(event) {
        event.preventDefault();
        let errors = this.state.errors;
        try {
            userService.GetPhoneNoValidate(this.state.Phone)
                .then(apidata => {

                    if (apidata[0].code !== "1") {
                        errors.Phone = 'Invalid Phone Number';
                    }
                    else {
                        if (apidata[0].message != null) {
                            this.setState({ Phone: apidata[0].message })
                        }
                    }

                    if (this.state.UserName) {
                        let str = this.state.UserName;
                        str = str.replace(/^\s+/, '');
                        this.setState({ UserName: str });
                        if (this.state.UserName !== "") {
                            var special = /^[0-9\b]+$/;
                            if (this.state.UserName.match(special)) {
                                errors.UserName = 'This Username not allowed';
                            }
                        }
                    }
                    if (this.state.Fname) {
                        let str = this.state.Fname;
                        str = str.replace(/^\s+/, '');
                        this.setState({ Fname: str });
                        if (this.state.Fname !== "") {
                            let special = /^[0-9\b]+$/;
                            if (this.state.Fname.match(special)) {
                                errors.Fname = 'This Fullname not allowed';
                            }
                        }
                    }

                    if (this.state.Commission) {
                        if (this.state.Commission !== "") {
                            let special = /^\d+\.?\d{1,2}$/;
                            if (this.state.Commission.match(special)) {
                            }
                            else {
                                errors.Commission = 'Please enter amount upto two decimal ';
                            }
                        }
                    }

                    if (this.state.SecLevelCommission) {
                        if (this.state.SecLevelCommission !== "") {
                            let special = /^\d+\.?\d{1,2}$/;
                            if (this.state.SecLevelCommission.match(special)) {
                            }
                            else {
                                errors.SecLevelCommission = 'Please enter amount upto two decimal ';
                            }
                        }
                    }
                    if (this.state.Commission) {
                        if (this.state.Commission < 0.01) {
                            errors.Commission = 'Please enter valid commission';
                        }
                    }
                    if (this.state.OverrideCommission) {
                        if (this.state.OverrideCommission !== "") {
                            if (this.state.OverrideCommission.toString().split('.').length > 2) {
                                errors.OverrideCommission = 'Please enter valid commission';
                            }
                            else if ((this.state.OverrideCommission) > 2.75) {
                                errors.OverrideCommission = 'Comm. exceeding Wholesaler max Comm.';
                            }
                            //var special = /^\d+\.?\d{1,2}$/;
                            //if (this.state.OverrideCommission.match(special)) {
                            //}
                            //else {
                            //    errors.OverrideCommission = 'Please enter amount upto two decimal ';
                            //}
                        }
                    }
                   
                    if (this.state.OverrideCommission) {
                        if (this.state.OverrideCommission < 0.01) {
                            errors.OverrideCommission = 'Please enter valid override commission';
                        }
                    }
                    if (this.state.SecLevelCommission) {
                        if (this.state.SecLevelCommission < 0.01) {
                            errors.SecLevelCommission = 'Please enter valid 2nd level commission';
                        }
                    }

                    if (this.state.UserName.length < 5)
                        errors.UserName = 'User Name must be at least 5 characters long';
                    if (this.state.UserName.includes("user") || this.state.UserName.includes("rxh") || this.state.UserName.includes("temp"))
                        errors.UserName = 'This Username not allowed';
                    if (this.state.UserName.includes(' '))
                        errors.UserName = 'User Name does not allow space';
                    if (this.state.Fname.length < 3)
                        errors.Fname = 'Full name must be at least 3 characters long';
                    if (this.state.Fname.trim() === "")
                        errors.Fname = 'Please enter valid Full Name';
                    if (this.state.Phone.replace(/[- )(]/g, '').length < 10 || this.state.Phone.replace(/[- )(]/g, '').length > 10)
                        errors.Phone = 'Phone Number must be 10 digits';
                    //if (userService.ValidPhone(this.state.Phone) == false && this.state.Phone != "")
                    //    errors.Phone = 'Please enter valid Phone Number';
                    //if (this.state.Address1.length < 1)
                    //    errors.Address1 = 'Please Input street address';
                    //if (this.state.City.length < 2)
                    //    errors.City = 'Please input city';
                    //if (this.state.Country == '')
           // errors.Country = 'Please Select Country!';
                    //if (this.state.State === '')
                    //    errors.State = 'Please Select State!';
                    //if (this.state.Zip.length < 5 || this.state.Zip.length > 5)
                    //    errors.Zip = 'Please input valid zipcode';
                    if (this.state.Commission.length < 1 || this.state.Commission > 10)
                        errors.Commission = 'Please input valid commission';
                    if (this.state.SecLevelCommission.length < 1 || this.state.SecLevelCommission > 10)
                        errors.SecLevelCommission = 'Please input valid commission';
                    if (this.state.OverrideCommission.length < 1 || this.state.OverrideCommission > 10)
                        errors.OverrideCommission = 'Please input valid override commission';
                    if (JSON.stringify(errors) !== JSON.stringify(this.noerrors))
                        this.setState({ errors: errors });
                    else {
                        let states_data = this.state;
                        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
                        this.UpdateUserDetail(Usermeta_Data.split(':')[1], Usermeta_Data.split(':')[0], states_data.UserName, this.props.repid, states_data.Fname, states_data.Email, states_data.Phone, states_data.group_no, states_data.Address1, '', 'United States', states_data.State, states_data.City, states_data.Zip, states_data.Commission, states_data.OverrideCommission, states_data.SecLevelCommission, states_data.AllowForGC, event)
                        $('body').css('overflow-y', 'auto');
                    }
                })
        }
        catch (error) {
        }
    }

    HandleInput = (event) => {
        let errors = this.state.errors;
        if (event.target.name === "Phone") {
            var regex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
            if (event.target.value.match(regex)) {
                this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                errors[event.target.name] = '';
                this.setState({ errors: errors });
            }
        }
        if (event.target.name === "City") {
            if (event.target.value !== "") {
                var special = /^[a-zA-Z ]*$/;///^[a-zA-Z]+$/g;
                if (event.target.value.match(special) || event.target.value === '') {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name] = '';
                    this.setState({ errors: errors });
                }
                else {
                    return false;
                }
            }
            else {
                this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
            }
        }
        if (event.target.name === "Zip") {
            if (event.target.value !== "" || event.target.value === "") {
                let special = /^[0-9\b]+$/;
                if (event.target.value.match(special) || event.target.value === "") {
                    this.setState({ [event.target.name]: event.target.value, extra_info: false, extra_info_msg: '' });
                    errors[event.target.name] = '';
                    this.setState({ errors: errors });
                }
            }
        }
        if (event.target.name === "AllowForGC") {
            this.setState({ AllowForGC: !this.state.AllowForGC })
        }
        else {
            this.setState({ [event.target.name]: event.target.value });
            errors[event.target.name] = '';
            this.setState({ errors: errors });
        }
    }
    handleChange = (event => {
        this.setState({ [event.target.name]: event.target.value });
    })
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name] = '';
        this.setState({ errors: errors });
    }
    DetailOfUser(UserId, UserName, RepId) {
        try {
            userService.DetailOfUser(UserId, UserName, RepId)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            let apivalue = apidata[0];
                            this.setState({
                                UserName: apivalue.userName || '', Fname: (apivalue.firstName + " " + apivalue.lastName).trim() || '', Phone: apivalue.phone || '', Email: apivalue.email || '', Address1: apivalue.address1 || '', Country: apivalue.countryName || '', State: apivalue.stateName || '', City: apivalue.cityName || '', Zip: apivalue.zipCode || '', group_no: apivalue.groupNumber || '', Commission: apivalue.commission || '',
                                //SecLevelCommission: apivalue.SecLevelCommission || '',Commented because duplicate found
                                OverrideCommission: apivalue.overrideCommission || '', CreatedOn: apivalue.createdOn || '', SecLevelCommission: apivalue.grandchildCommission || '0.10', AllowForGC: apivalue.isAllowedForGC || false
                            })
                        }

                    }
                );
        }
        catch (error) {
        }
    }
    UpdateUserDetail(UserName, UserId, RepUserName, RepId, RepName, Email, Phone, GN, Add1, Add2, CoName, StName, CtName, ZipCode, Commission, OverrideCommission, SecLevelCommission, IsAllowedForGC, event) {
        this.setState({ submit_msg: '' });
        this.setState({ save_loader: true });
        try {
            userService.UpdateUserDetail(UserName, UserId, RepUserName, RepId, RepName, Email, Phone, GN, Add1, Add2, CoName, StName, CtName, ZipCode, Commission, OverrideCommission, SecLevelCommission, IsAllowedForGC)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            let username = "";
                            username = apidata[0].statusMessage;
                            if (apidata[0].code === '1') {
                                if (apidata.length > 1) {
                                    let comm = "";
                                  
                                    comm = apidata[1].code;
                                    if (apidata[1].statusMessage == "") {
                                        this.setState({
                                            submit_msg: "Commission for all groups associated with " + username + " have been updated to " + comm
                                        });
                                        this.setState({ save_loader: false });
                                    }
                                    else if (apidata[1].statusMessage == "Custom Value Set") {
                                        this.setState({ submit_msg: "Commission for no groups updated as all of " + username + " groups have custom commission values set" });
                                        this.setState({ save_loader: false });
                                    }
                                    
                                    else {
                                        let msg = [];
                                        apidata.forEach((i, index) => {
                                            let gn = i.statusMessage;
                                            if (gn == username) {
                                            }
                                            else {
                                                msg.push(gn);
                                            }
                                        });

                                        if (msg.length == 1) {
                                            this.setState({
                                                submit_msg: "Commission for some groups associated with " + username + " have been updated to " + comm + " .This group number is not updated: " + msg
                                            });
                                            this.setState({ save_loader: false });
                                        }
                                        else {
                                            this.setState({
                                                submit_msg: "Commission for some groups associated with " + username + " have been updated to " + comm + " .These groups were not updated: " + msg
                                            });
                                            this.setState({ save_loader: false });
                                        }
                                    }
                                }
                                    
                                else {
                                    this.setState({ submit_msg: "Updated Successfully" });
                                    this.setState({ save_loader: false });
                                }
                                //this.props.toggle_add_edit_popup(event)
                            }
                            else {
                                this.setState({ submit_msg: apidata[0].statusMessage });
                                this.setState({ save_loader: false });
                            }
                        }

                    }
                );
        }
        catch (error) {
        }
    }
    render() {
        let { title } = this.props;
        const { errors } = this.state;
        return (
            <div className="add_url_pop change-new">
                <div className="top-aligned_popup">
                    <div className="header">
                        {title}
                        <div className="close" onClick={this.props.toggle_add_edit_popup}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>
                    <div className="scroll-section-part">
                        <div className="body">
                            <h3>Personal </h3>
                            <div className="personal_box">
                                <div>
                                    <div className={errors.UserName.length > 0 ? 'errors' : ''}>
                                        <input type="text" placeholder=" " name="UserName" value={this.state.UserName} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                        <span>Username</span>
                                        {errors.UserName.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.UserName} </span>}
                                    </div>
                                    <div className={errors.Fname.length > 0 ? 'errors' : ''}>
                                        <input type="text" placeholder=" " name="Fname" value={this.state.Fname} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                        <span>Full Name</span>
                                        {errors.Fname.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.Fname} </span>}
                                    </div>
                                </div>
                                <div>
                                    <div className={errors.Phone.length > 0 ? 'errors' : ''}>
                                        <input type="text" placeholder=" " name="Phone" value={this.state.Phone} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                        <span>Phone</span>
                                        {errors.Phone.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.Phone} </span>}
                                    </div>
                                    <div>
                                        <input type="text" placeholder=" " value={this.state.Email} className="disabled" onChange={this.handle_user} name="email" />
                                        <span>Email</span>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <input type="text" placeholder=" " value={this.state.group_no} className="disabled" onChange={this.handle_user} name="primary_grp" />
                                        <span>Primary Group Number</span>
                                    </div>
                                    <div>
                                        <input type="text" placeholder=" " value={(this.state.CreatedOn).split('T')[0]} className="disabled" name="date_createdon" />
                                        <span>User Sign Up Date</span>
                                    </div>
                                </div>
                            </div>
                            <h3>Address</h3>
                            <div className="personal_box">
                                <div>
                                    <div className={errors.Address1.length > 0 ? 'width100 errors' : 'width100'}>
                                        <input type="text" placeholder=" " name="Address1" value={this.state.Address1} onChange={this.HandleInput} />
                                        <span>Street Address</span>
                                        {errors.Address1.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.Address1} </span>}
                                    </div>
                                </div>
                                <div>
                                    <div className={errors.Country.length > 0 ? 'drpdown errors' : 'drpdown'}>
                                        <select value={this.state.Country}
                                            onChange={this.handleChange} name="Country">
                                            {this.state.AddressData.Country.map(item => <option key={item} value={item} >{item}</option>)}
                                        </select>


                                        <span>Country</span>
                                        {errors.Country.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.Country} </span>}

                                    </div>
                                    <div className={errors.State.length > 0 ? 'drpdown errors' : 'drpdown'}>

                                        <select value={this.state.State}
                                            onChange={this.handleChange} name="State">
                                            {this.state.AddressData.State.map(item => <option key={item.name} value={item.value} >{item.name}</option>)}
                                        </select>

                                        <span>State</span>
                                        {errors.State.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.State} </span>}

                                    </div>
                                </div>
                                <div>
                                    <div className={errors.Zip.length > 0 ? 'errors' : ''}>
                                        <input type="text" placeholder=" " name="Zip" value={this.state.Zip} onChange={this.HandleInput} />
                                        <span>ZIP / Postal Code</span>
                                        {errors.Zip.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.Zip} </span>}
                                    </div>
                                    <div className={errors.City.length > 0 ? 'errors' : ''}>
                                        <input type="text" placeholder=" " name="City" value={this.state.City} onChange={this.HandleInput} />
                                        <span>City</span>
                                        {errors.City.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.City} </span>}
                                    </div>
                                </div>
                            </div>
                            <h3>Commission</h3>
                            <div className="personal_box">
                                <div>
                                    <div className={errors.Commission.length > 0 ? 'errors' : ''}>
                                        <input type="text" placeholder=" " name="Commission" value={this.state.Commission} onChange={this.HandleInput} onKeyPress={(event) => {
                                            if (/[^0-9\.]/g.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} />
                                        <span>Commission</span>
                                        {errors.Commission.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.Commission} </span>}
                                    </div>
                                    <div className={errors.OverrideCommission.length > 0 ? 'errors' : ''}>
                                        <input type="text" placeholder=" " name="OverrideCommission" value={this.state.OverrideCommission} onChange={this.HandleInput} onKeyPress={(event) => {
                                            if (/[^0-9\.]/g.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} />
                                        <span>Override Commission</span>
                                        {errors.OverrideCommission.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.OverrideCommission} </span>}
                                    </div>
                                </div>
                            </div>
                            <div className="personal_box">
                                <div>
                                    <div className={errors.SecLevelCommission.length > 0 ? 'errors' : ''}>
                                        <input type="text" placeholder=" " name="SecLevelCommission" value={this.state.SecLevelCommission} onChange={this.HandleInput} onKeyPress={(event) => {
                                            if (/[^0-9\.]/g.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} />
                                        <span>2nd Level Commission</span>
                                        {errors.SecLevelCommission.length > 0 &&
                                            <span onClick={this.removeError.bind(this)}>{errors.SecLevelCommission} </span>}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type="checkbox" id="AllowForGC" name="AllowForGC" value="AllowForGC" checked={this.state.AllowForGC} onChange={this.HandleInput} />
                                        <span htmlFor="AllowForGC">Enable Second Level Commission</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <div className={this.state.save_loader ? 'yes_btn loader' : 'yes_btn'}  onClick={this.submit_setting.bind(this)} >Save Changes</div>
                            <div className="no_btn" onClick={this.props.toggle_add_edit_popup}>Cancel</div>
                            {this.state.submit_msg.length ?
                                <div className="error_submit_msg">{this.state.submit_msg}</div>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Edit_user_pop;
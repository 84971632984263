import React, { Component } from "react";
import close_btn from '../../../images/close_btn.svg';
import { userService } from "../../../_services";
//import $ from 'jquery';Commented because not in use
class Edit_user_pop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: '',
            Fname: '',
            Phone: '',
            Email: '',
            Address1: '',
            Country: 'United States',
            State: '',
            City: '',
            Zip: '',
            group_no: '',
            submit_msg:'',
            errors: {
                UserName: '',
                Fname: '',
                Phone: '',
                Address1: '',
                Country: '',
                State: '',
                City: '',
                Zip: ''

            },
            AddressData: {
                Country: [
                    'United States'
                ],
                State: [
                    'Please select state',
                    'Alabama',
                    'Alaska',
                    'Arizona',
                    'Arkansas',
                    'California',
                    'Colorado',
                    'Connecticut',
                    'Delaware',
                    'District of Columbia',
                    'Florida',
                    'Georgia',
                    'Hawaii',
                    'Idaho',
                    'Illinois',
                    'Indiana',
                    'Iowa',
                    'Kansas',
                    'Kentucky',
                    'Louisiana',
                    'Maine',
                    'Maryland',
                    'Massachusetts',
                    'Michigan',
                    'Minnesota',
                    'Mississippi',
                    'Missouri',
                    'Montana',
                    'Nebraska',
                    'Nevada',
                    'New Hampshire',
                    'New Jersey',
                    'New Mexico',
                    'New York',
                    'North Carolina',
                    'North Dakota ',
                    'Ohio',
                    'Oklahoma',
                    'Oregon',
                    'Pennsylvania',
                    'Rhode Island',
                    'South Carolina',
                    'South Dakota',
                    'Tennessee',
                    'Texas',
                    'Utah',
                    'Vermont',
                    'Virginia',
                    'Washington',
                    'West Virginia',
                    'Wisconsin',
                    'Wyoming',
                    'American Samoa',
                    'Guam',
                    'Northern Mariana Islands',
                    'Puerto Rico',
                    'Virgin Islands',
                ]
            },
        }
            this.noerrors = {
                UserName: '',
                Fname: '',
                Phone: '',
                Address1: '',
                Country: '',
                State: '',
                City: '',
                Zip: ''
            }
        this.submit_setting = this.submit_setting.bind(this)
        
    }
    componentDidMount() {
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
        this.DetailOfUser(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1], this.props.repid)
        
    }
    submit_setting(event) {
        event.preventDefault();
        let errors = this.state.errors;
        if (this.state.UserName.length < 3)
            errors.UserName = 'User Name must be at least 3 characters long';
        if (this.state.Fname.length < 3)
            errors.Fname = 'First name must be at least 3 characters long';
        if (this.state.Phone.replace(/[- )(]/g, '').length < 10 || this.state.Phone.replace(/[- )(]/g, '').length > 10)
            errors.Phone = 'Phone Number must be 10 digits';
        if (this.state.Address1.length < 1)
            errors.Address1 = 'Please Input street address';
        if (this.state.City.length < 2)
            errors.City = 'Please input city';
        //if (this.state.Country == '')
        //    errors.Country = 'Please Select Country!';
        if (this.state.State === '')
            errors.State = 'Please Select State!';
        if (this.state.Zip.length < 5)
            errors.Zip = 'Please input valid zipcode';
        if (JSON.stringify(errors) !== JSON.stringify(this.noerrors))
            this.setState({ errors: errors });
        else {
            let states_data = this.state;
            let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));
            this.UpdateUserDetail(Usermeta_Data.split(':')[1], Usermeta_Data.split(':')[0], states_data.UserName, this.props.repid, states_data.Fname, states_data.Email, states_data.Phone, states_data.group_no, states_data.Address1, '', 'United States', states_data.State, states_data.City, states_data.Zip, event)
        }
    }
    HandleInput = (event) => {
        let errors = this.state.errors;
        this.setState({ [event.target.name]: event.target.value });
        errors[event.target.name] = '';
        this.setState({ errors: errors });
    }
    handleChange = (event => {
        this.setState({ [event.target.name]: event.target.value });
    })
    removeError = (event) => {
        let errors = this.state.errors;
        errors[event.target.previousElementSibling.previousElementSibling.name] = '';
        this.setState({ errors: errors });
    }
    DetailOfUser(UserId, UserName, RepId) {
        try {
            userService.DetailOfUser(UserId, UserName, RepId)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            let apivalue = apidata[0];
                            this.setState({
                                UserName: apivalue.userName || '', Fname: (apivalue.firstName + " " + apivalue.lastName).trim() || '', Phone: apivalue.phone || '', Email: apivalue.email || '', Address1: apivalue.address1 || '', Country: apivalue.countryName || '', State: apivalue.stateName || '', City: apivalue.cityName || '', Zip: apivalue.zipCode || '', group_no: apivalue.groupNumber || ''
                            })
                        }
                        
                    }
                );
        }
        catch (error) {
        }
    }
    UpdateUserDetail(UserName, UserId, RepUserName, RepId, RepName, Email, Phone, GN, Add1, Add2, CoName, StName, CtName, ZipCode, event) {
        this.setState({ submit_msg: '' });
        try {
            userService.UpdateUserDetail(UserName, UserId, RepUserName, RepId, RepName, Email, Phone, GN, Add1, Add2, CoName, StName, CtName, ZipCode)
                .then(
                    apidata => {
                        if (apidata.length > 0) {
                            if (apidata[0].code === '1') {
                                this.props.toggle_add_edit_popup(event)
                            }
                            else {
                                this.setState({ submit_msg: apidata[0].statusMessage });
                            }
                        }

                    }
                );
        }
        catch (error) {
        }
    }
    render() {
        let { title } = this.props;
        const { errors } = this.state;
        return (
            <div className="add_url_pop">
                <div>
                    <div className="header">
                        {title}
                        <div className="close" onClick={this.props.toggle_add_edit_popup}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>
                    
                    <div className="body">
                        <h3>Personal </h3>
                        <div className="personal_box">
                            <div>
                                <div className={errors.UserName.length > 0 ? 'errors' : ''}>
                                    <input type="text" placeholder=" " name="UserName" value={this.state.UserName} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                    <span>Username</span>
                                    {errors.UserName.length > 0 &&
                                        <span>{errors.UserName} </span>}
                                </div>
                                <div className={errors.Fname.length > 0 ? 'errors' : ''}>
                                    <input type="text" placeholder=" " name="Fname" value={this.state.Fname} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                    <span>Full Name</span>
                                    {errors.Fname.length > 0 &&
                                        <span onClick={this.removeError.bind(this)}>{errors.Fname} </span>}
                                </div>
                            </div>
                            <div>
                                <div className={errors.Phone.length > 0 ? 'errors' : ''}>
                                    <input type="text" placeholder=" " name="Phone" value={this.state.Phone} onChange={this.HandleInput} onFocus={this.HandleInput} />
                                    <span>Phone</span>
                                    {errors.Phone.length > 0 &&
                                        <span onClick={this.removeError.bind(this)}>{errors.Phone} </span>}
                                </div>
                                <div>
                                    <input type="text" placeholder=" " value={this.state.Email} className="disabled" onChange={this.handle_user} name="email" />
                                    <span>Email</span>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <input type="text" placeholder=" " value={this.state.group_no} className="disabled" onChange={this.handle_user} name="primary_grp" />
                                    <span>Primary Group Number</span>
                                </div>
                            </div>
                        </div>
                        <h3>Address</h3>
                        <div className="personal_box">
                            <div>
                                <div className={errors.Address1.length > 0 ? 'width100 errors' : 'width100'}>
                                    <input type="text" placeholder=" " name="Address1" value={this.state.Address1} onChange={this.HandleInput} />
                                    <span>Street Address</span>
                                    {errors.Address1.length > 0 &&
                                        <span onClick={this.removeError.bind(this)}>{errors.Address1} </span>}
                                </div>
                            </div>
                            <div>
                                <div className={errors.Country.length > 0 ? 'drpdown errors' : 'drpdown'}>
                                    <select value={this.state.Country}
                                        onChange={this.handleChange} name="Country">
                                        {this.state.AddressData.Country.map(item => <option key={item} value={item} >{item}</option>)}
                                    </select>


                                    <span>Country</span>
                                    {errors.Country.length > 0 &&
                                        <span onClick={this.removeError.bind(this)}>{errors.Country} </span>}

                                </div>
                                <div className={errors.State.length > 0 ? 'drpdown errors' : 'drpdown'}>

                                    <select value={this.state.State}
                                        onChange={this.handleChange} name="State">
                                        {this.state.AddressData.State.map(item => <option key={item} value={item} >{item}</option>)}
                                    </select>

                                    <span>State*</span>
                                    {errors.State.length > 0 &&
                                        <span onClick={this.removeError.bind(this)}>{errors.State} </span>}

                                </div>
                            </div>
                            <div>
                                <div className={errors.Zip.length > 0 ? 'errors' : ''}>
                                    <input type="text" placeholder=" " name="Zip" value={this.state.Zip} onChange={this.HandleInput} />
                                    <span>ZIP / Postal Code*</span>
                                    {errors.Zip.length > 0 &&
                                        <span onClick={this.removeError.bind(this)}>{errors.Zip} </span>}
                                </div>
                                <div className={errors.City.length > 0 ? 'errors' : ''}>
                                    <input type="text" placeholder=" " name="City" value={this.state.City} onChange={this.HandleInput} />
                                    <span>City</span>
                                    {errors.City.length > 0 &&
                                        <span onClick={this.removeError.bind(this)}>{errors.City} </span>}
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="footer">
                        <div className="yes_btn" onClick={this.submit_setting.bind(this)} >Save Changes</div>
                        <div className="no_btn" onClick={this.props.toggle_add_edit_popup}>Cancel</div>
                        {this.state.submit_msg.length ?
                            <div className="error_submit_msg">{this.state.submit_msg}</div>
                            : null}
                        </div>
                </div>
            </div>
        )
    }
}
export default Edit_user_pop;
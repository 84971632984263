import React, { Component } from 'react';
import SignUp from "./components/Register/SignUp";
import Activate_Account from "./components/Register/Activate_Account";
import PassRedirect from "./components/Register/passredirect";
import Activate_SecAccount from "./components/Register/Activate_SecAccount";
import LogIn from "./components/Login/login";
import HomePage from "./components/Homepage/homepage";
import Affiliate_Url from "./components/Affiliate_Url/Affiliate_Url";
import SummaryPage from "./components/Summary/Summary";
import ConsumerSummaryPage from "./components/Consumer/Summary";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";// Link Commented because not in use
import { PrivateRoute } from "./privateRoute";
import './custom.css';
import Account_Info_page from './components/Account_Info/Account_Info';
import OrderCardsPage from './components/Order_Cards/OrderCardsPage';
import ConsumerOrderCardsPage from './components/Consumer/ShareCard';
import ConsumerLogIn from "./components/Consumer/ConsumerLogin";
import ClaimDashboardPage from './components/Claim_Dashboard/ClaimDashboard';
import FaqPage from './components/FAQ_s/FaqPage';
import ConsumerFaqPage from './components/Consumer/FaqPage';
import { userService } from "./_services";
import Groupno_Page from './components/Admin/Group_no/GroupnoPage';
import Transactions from './components/widget/admin/Transactions';
import UserTransactions from './components/widget/user/Transactions';
import Analytics from './components/widget/admin/Analytics';
import Geographic from './components/widget/admin/Geographic';
import Pharmacies from './components/widget/admin/Pharmacies';
import User_Page from './components/Admin/Users/UsersPage';
import Refferallink_Page from './components/Admin/Refferal_Link/Refferallink_Page';
import PricingPage from './components/Admin/Pricing_tool/PricingPage';
import ForgetPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ResetPassword/ResetPassword';
import RuserSetup from './components/RuserSetup/SetUsername';
import OuserSetup from './components/RuserSetup/OSetUsername';
import TermsPage from './components/terms/TermsPage';
import CompliancePage from './components/compliance/CompliancePage';
import { Helmet } from "react-helmet";
import packageJson from '../package.json';
//import packageJson from "./package.json";
//import withClearCache from "./ClearCache";
//import { getBuildDate } from "./utils";
import $ from 'jquery';
//import Report_Page from './components/Admin/Reports/ReportPage';
import TimifitPage from './components/timifit/Timifit';
import Links_Page from './components/Admin/Secondary_Links/LinksPage';
import ErrorPage from './components/404-error/404';
import AdminTraining from './components/Admin/Training/AdminTraining';
import ConsumerTraining from './components/Consumer/Training/Training';
import UserTraining from './components/TrainingPage/Training';
import CommissionTracking from './components/Admin/Reports/CommissionTracking';
// import Account_Info_page from './components/Account_Info/Account_Info';
import Consumer_Account_Info from './components/Consumer/Account_Info/Account_Info';
import Cookies from 'js-cookie';

export default class App extends Component {
    static displayName = App.name;

    componentDidMount() {
        try {
            const _currentUrl = window.location.href;
            let _pathStr = window.location.pathname;
            const _pattern = /^https:\/\/backoffice\.rxhacker\.com\/ref\/\w+$/;
            const _isMatch = _pattern.test(_currentUrl);
            if (_isMatch) {
                const _parts = _pathStr.split('/');
                const _extracted = _parts[_parts.length - 1];
                this.checkValidateRefLink(_extracted);
            }
        }
        catch { }
        if (window.location.href.indexOf('/Account/Activate?uid=') <= -1) {
            this.handle_caching();
        }
        $(document).on('click', '.menu_btn', function () {
            $('.left_sidebar').addClass('menu_open');
        });
        $(document).on('click', '.left_sidebar .close_btn', function () {
            $('.left_sidebar').removeClass('menu_open');
        });
        $(document).on('click', '.mobile_menu_icon', function (e) {
            e.stopImmediatePropagation();
            $('.mobile_menu_icon + div').removeClass('action_menu_open');
            $(this).next().addClass('action_menu_open');
        });
        $(window).on('click', function () {
            setTimeout(() => {
                $('.mobile_menu_icon + div').removeClass('action_menu_open');
            }, 300);
        });
        if (window.location.href.includes('/timifit')) {
            localStorage.setItem('pagename', 'fit-economy');
            return <Redirect to="/fit-economy" />
        }
        if (!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id"))) {
            if (window.atob(localStorage.getItem('_Host_Pro_type')) !== "Admin") {
                if (!window.location.href.includes('/signup')) {
                    return <Redirect to="/signup" />
                }
            }
            else {
                if (!window.location.href.includes('/signup')) {
                    return <Redirect to="/signup" />
                }
            }
        }
    }
    componentDidUpdate() {
        if (window.location.href.includes('/timifit')) {
            localStorage.setItem('pagename', 'fit-economy');
            return <Redirect to="/fit-economy" />
        }
        if (!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id"))) {
            if (window.atob(localStorage.getItem('_Host_Pro_type')) !== "Admin") {
                if (!window.location.href.includes('/signup')) {
                    return <Redirect to="/signup" />
                }
            }
            else {
                if (!window.location.href.includes('/signup')) {
                    return <Redirect to="/signup" />
                }
            }
        }
    }
    //  MainApp(props) {
    //    return (
    //      <div>
    //        <p>Build date: {getBuildDate(packageJson.buildDate)}</p>
    //  </div>
    //);
    //}
    handle_caching() {
        try {
            let version = localStorage.getItem('version');
            if (version !== packageJson.version) {
                if ('caches' in window) {
                    caches.keys().then((names) => {
                        names.forEach(name => {
                            caches.delete(name);
                        })
                    });
                    window.location.reload(true);
                    window.location.href = window.location.href;
                }
                localStorage.removeItem('version');
                localStorage.setItem('version', packageJson.version);
            }
            else {
                return false;
            }
        } catch (err) {


        }
    }
    checkDeactivateAccount = (UserId, UserName) => {
        try {
            userService.DeactivateAccount(UserId, UserName)
                .then(
                    apidata => {
                        try {
                            if (apidata.length > 0) {
                                if (apidata[0].userStatus === 'False') {
                                    userService.logout();
                                    window.location.reload();
                                }
                            }
                        } catch (error) { }
                    }
                );
        }
        catch (error) { }
    }
    checkValidateRefLink = (endPoint) => {
        try {
            userService.Validate_ReferralURL('', endPoint, '')
                .then(
                    apidata => {
                        try {
                            if (apidata.length > 0) {
                                if (apidata[0].code == '-1') {
                                    localStorage.clear();
                                    localStorage.removeItem('ajs_user_id');
                                    localStorage.removeItem('ReferralURL');
                                    localStorage.removeItem('_Host_Pro_Hckare_TKN');
                                    localStorage.removeItem('_Secure_3PSID_DT');
                                    localStorage.removeItem('ReferralURL');
                                    try {
                                        setTimeout(() => {
                                            window.location.href = "http://mypagenotfound.com";
                                        }, 10);
                                    }
                                    catch (err) {
                                    }
                                }
                            }
                            else {
                            }
                        } catch (error) { }
                    }
                );
        }
        catch (error) { }
    }

    render() {
        const currentUrl = window.location.href;
        let pathStr = window.location.pathname;
        try {
            const currentUrl = window.location.href;
            const pattern = /^https:\/\/backoffice\.rxhacker\.com\/ref\/\w+$/;
            const isMatch = pattern.test(currentUrl);
            /*if ((pathStr.includes("/ref/E81W047G") && window.location.href.includes("https://backoffice.rxhacker.com/ref/E81W047G")) || (pathStr.includes("/ref/E81W047G") && window.location.href.includes("https://localhost:44312/ref/E81W047G"))) {*/
            if (isMatch) {
                const parts = pathStr.split('/');
                const extracted = parts[parts.length - 1];
                this.checkValidateRefLink(extracted);
            }
        }
        catch {
        }
        pathStr = pathStr.charAt(pathStr.length - 1) === '/' ? pathStr.substr(0, pathStr.length - 1) : pathStr;
        if (pathStr !== '/signup') {
            window.localStorage.setItem('cp_pat_bo', pathStr)
        }
        try {
            if (window.opener && window.self.name === "popup") {
                var wparam = localStorage.getItem('wt19:awaydpset');
                if (wparam !== null && wparam != undefined && wparam !== '') {
                    let getlogin = window.location.href;
                    if (getlogin.toLowerCase().includes("release-backoffice") || getlogin.toLowerCase().includes("dev-backoffice")) {
                        window.opener.location.href = 'http://dev.glichealth.com/login?q=' + wparam;
                        window.self.close();
                    }
                    else if (getlogin.toLowerCase().includes("localhost:")) {
                        window.opener.location.href = 'http://localhost:5464/login?q=' + wparam;
                        window.self.close();
                    }
                    else {
                        window.opener.location.href = 'http://reporting.rxhacker.com/login?q=' + wparam;
                        window.self.close();
                    }
                }
            }
        }
        catch (error) {
        }
        if (window.location.href.includes('backoffice.rxhacker.com/ref/')) {
            try {
                let currentUrll = window.location.href;
                let patternn = /^https:\/\/backoffice\.rxhacker\.com\/ref\/\w+$/;
                let isMatchh = patternn.test(currentUrll);
                if (isMatchh) {
                    let partss = pathStr.split('/');
                    let extractedd = partss[partss.length - 1];
                    if (extractedd != '' && extractedd != undefined) {
                        this.checkValidateRefLink(extractedd);
                    }
                }
            }
            catch {

            }
            localStorage.setItem('ReferralURL', window.location.href);
            try {
                var parts = window.location.hostname.split('.');
                var sndleveldomain = parts.slice(-2).join('.');
                Cookies.set('mainframe_url_clb', window.location.href, { domain: sndleveldomain, expires: 7, secure: true, sameSite: 'None', path: '/' }); // Expires in 7 days
            }
            catch (error) { }
        }
        if (window.location.href.includes('/ref?sign_completed')) {
            localStorage.setItem('AffiliateReferralURL', window.location.href);
        }
        if (window.location.href.includes('/affiliate?sign_completed')) {
            localStorage.setItem('AffiliateReferralURL', window.location.href);
        }
        if (window.location.href.includes('/affiliate')) {
            localStorage.setItem('ReferralURL', window.location.href);
            try {
                var parts = window.location.hostname.split('.');
                var sndleveldomain = parts.slice(-2).join('.');
                Cookies.set('mainframe_url_clb', window.location.href, { domain: sndleveldomain, expires: 7, secure: true, sameSite: 'None', path: '/' }); // Expires in 7 days
            }
            catch (error) { }
        }
        if (window.location.href.includes('/ref')) {
            localStorage.setItem('ReferralURL', window.location.href);
            try {
                var parts = window.location.hostname.split('.');
                var sndleveldomain = parts.slice(-2).join('.');
                Cookies.set('mainframe_url_clb', window.location.href, { domain: sndleveldomain, expires: 7, secure: true, sameSite: 'None', path: '/' }); // Expires in 7 days
            }
            catch (error) { }
        }
        if (window.location.href.includes('/signup')) {
            let pattern = /\/ref\/[a-zA-Z0-9]/g;
            let refUrl = localStorage.getItem('ReferralURL');
            if (!pattern.test(refUrl)) {
                localStorage.setItem('ReferralURL', window.location.href);
            }
        }
        if (window.location.href.includes('/timifit')) {
            localStorage.setItem('pagename', 'fit-economy');
            // return <Redirect to="/fit-economy" />
        }
        let Usermeta_Data = window.atob(localStorage.getItem('ajs_user_id'));

        if (window.location.href.toLowerCase().includes('/consumer/summary')) {
            localStorage.setItem('pagename', 'consumerSummaryPage');
        }
        if (window.location.href.toLowerCase().includes('/consumer/faq')) {
            localStorage.setItem('pagename', 'consumerFaqNew');
        }
        if (!userService.Check_Null_Empty(localStorage.getItem("ajs_user_id"))) {
            this.checkDeactivateAccount(Usermeta_Data.split(':')[0], Usermeta_Data.split(':')[1]);
            if (window.atob(localStorage.getItem('_Host_Pro_type')) !== "Admin") {
                if (!window.location.href.includes('/signup')) {
                    // return <Redirect to="/signup" />
                }
            }
            else {
                if (!window.location.href.includes('/signup')) {
                    // return <Redirect to="/signup" />
                }
            }
        }
        return (
            <div>
                <Helmet>
                    <title>RxHacker Pro Backoffice</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1" shrink-to-fit="yes" />
                </Helmet>
                <Router>
                    <Switch>
                        <PrivateRoute exact path="/" component={HomePage} />

                        <Route path="/login" component={LogIn} >
                            <LogIn />
                        </Route>
                        <Route exact strict path="/">
                            <HomePage />
                        </Route>
                        <Route path="/signup">
                            <SignUp />
                        </Route>
                        <Route path="/signup/:linkSuffix">
                            <SignUp />
                        </Route>
                        <Route path="/Account/Activate">
                            <Activate_Account />
                        </Route>
                        <Route path="/passes/redirect">
                            <PassRedirect />
                        </Route>
                        <Route path="/Account/SecActivate">
                            <Activate_SecAccount />
                        </Route>
                        <Route path="/ref">
                            <Affiliate_Url />
                        </Route>
                        <Route path="/affiliate">
                            <Affiliate_Url />
                        </Route>
                        <Route path="/Summary">
                            <SummaryPage />
                        </Route>
                        <Route path="/consumer/Summary">
                            <ConsumerSummaryPage />
                        </Route>
                        <Route path="/consumer/account">
                            <Consumer_Account_Info />
                        </Route>
                        <Route path="/consumerlogin">
                            <ConsumerLogIn />
                        </Route>
                        <Route path="/account">
                            <Account_Info_page />
                        </Route>
                        <Route path="/cards">
                            <OrderCardsPage />
                        </Route>
                        <Route path="/consumer/cards">
                            <ConsumerOrderCardsPage />
                        </Route>
                        <Route path="/consumer/faq">
                            <ConsumerFaqPage />
                        </Route>
                        <Route path="/faq">
                            <FaqPage />
                        </Route>
                        <Route path="/forgotpassword">
                            <ForgetPassword />
                        </Route>
                        <Route path="/resetpassword">
                            <ResetPassword />
                        </Route>
                        <Route path="/rusersetup">
                            <RuserSetup />
                        </Route>
                        <Route path="/ousersetup">
                            <OuserSetup />
                        </Route>
                        <Route path="/Admin/transactions">
                            <Transactions />
                        </Route>
                        <Route path="/Admin/analytics/signups">
                            <Analytics />
                        </Route>
                        <Route path="/Admin/analytics/geographic">
                            <Geographic />
                        </Route>
                        <Route path="/Admin/analytics/pharmacies">
                            <Pharmacies />
                        </Route>
                        <Route path="/dashboard">
                            <UserTransactions />
                        </Route>
                        <Route path="/Admin/transactions">
                            <Transactions />
                        </Route>
                        <Route path="/Admin/analytics/signups">
                            <Analytics />
                        </Route>
                        <Route path="/Admin/analytics/geographic">
                            <Geographic />
                        </Route>
                        <Route path="/Admin/analytics/pharmacies">
                            <Pharmacies />
                        </Route>
                        <Route path="/user/transactions">
                            <UserTransactions />
                        </Route>
                        <Route path="/Admin/transactions">
                            <Transactions />
                        </Route>
                        <Route path="/Admin/analytics/signups">
                            <Analytics />
                        </Route>
                        <Route path="/Admin/analytics/geographic">
                            <Geographic />
                        </Route>
                        <Route path="/Admin/analytics/pharmacies">
                            <Pharmacies />
                        </Route>
                        <Route path="/Admin/groups">
                            <Groupno_Page />
                        </Route>
                        <Route path="/Admin/users" render={(props) => <User_Page {...props} />}>
                            <User_Page />
                        </Route>
                        <Route path="/Admin/pricing">
                            <PricingPage />
                        </Route>
                        <Route path="/Admin/referral">
                            <Refferallink_Page />
                        </Route>
                        <Route path="/Admin/secondary-link">
                            <Links_Page />
                        </Route>
                        <Route path="/terms">
                            <TermsPage />
                        </Route>
                        <Route path="/compliance">
                            <CompliancePage />
                        </Route>
                        <Route path="/fit-economy">
                            <TimifitPage />
                        </Route>
                        <Route path="/timifit">
                            <TimifitPage />
                        </Route>
                        <Route path="/oauth_callback" component={LogIn} />
                        <Route path="/ref">
                            <LogIn />
                        </Route>
                        <Route path="/Admin/report">
                            <CommissionTracking />
                        </Route>
                        <Route path="/Admin/training">
                            <AdminTraining />
                        </Route>
                        <Route path="/consumer/training">
                            <ConsumerTraining />
                        </Route>
                        <Route path="/training">
                            <UserTraining />
                        </Route>
                        <Route exact={true} path="/page-not-found">
                            <ErrorPage />
                        </Route>
                        <Redirect from='**' to='/page-not-found' />
                    </Switch>
                </Router>
            </div>
        );
    }
}
